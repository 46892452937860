export const colors = {
  primaryColor: `oklch(39.23% 0.112 263.08)`,
  primaryAlt: values =>
    variableColor({ l: `39.23%`, c: `0.112`, h: `263.08`, ...values }),
  secondaryColor: `oklch(86.52% 0.176 90.38)`,
  secondaryAlt: values =>
    variableColor({ l: `86.52%`, c: `0.176`, h: `90.38`, ...values }),
  mainYellow: `oklch(81.64% 0.145 93.09)`,
  darkYellow: `oklch(75.82% 0.107 82.58)`,
  mediumYellow: `oklch(86.28% 0.154 90.67 / 50%)`,
  lightPink: `oklch(39.23% 0.112 263.08)`,
  mainWhite: `oklch(100% 0 0)`,
  navLinkWhite: `oklch(100% 0 0 / 50%)`,
  mainBlack: `oklch(26.86% 0 0)`,
  darkBrown: `oklch(21.54% 0.066 24.61)`,
  mediumGrey: `oklch(68.44% 0.015 241.06)`,
  darkGrey: `oklch(39.79% 0 0)`,
}

export const variableColor = values => {
  const { l, c, h, a } = {
    l: `39.23%`,
    c: `0.112`,
    h: `263.08`,
    a: `1`,
    ...values,
  }
  return `oklch(${l} ${c} ${h} / ${a})`
}

export const textDefault = `font-family: 'Barlow', sans-serif;`
export const textSlanted = `font-family: 'Barlow', sans-serif;`
export const textNavLink = `font: normal 1.8em/1em 'Barlow', sans-serif;`
export const textBannerTitle = `font: normal 4.5em/0.9 'Courgette', cursive;`
export const textBannerSubTitle = `font: normal 1.5em/1em 'Barlow', sans-serif;`
export const textQuickInfoTitle = `font: normal 2em 'Barlow', sans-serif;`

export const fontBody = rules =>
  variableFont({ ...rules, fontFamily: `'Barlow', sans-serif` })

export const fontDecor = rules =>
  variableFont({
    ...rules,
    fontFamily: `'Courgette', cursive`,
    lineHeight: 0.9,
  })

const variableFont = rules => {
  const { fontFamily, lineHeight, size, style, weight } = {
    fontFamily: `'Barlow', sans-serif`,
    lineHeight: `1`,
    size: `1rem`,
    style: `normal`,
    weight: 400,
    ...rules,
  }

  return `${style} ${weight} ${size}/${lineHeight} ${fontFamily}`
}

export const transDefault = "transition:all 0.2s ease-in-out"
export const transFunction = (
  property = "all",
  time = "0.3s",
  type = "linear",
) => {
  return `transition:${property} ${time} ${type}`
}
export const transObject = ({
  property = "all",
  time = "0.3s",
  type = "ease-in-out",
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const transition = ({
  property = "all",
  time = "0.3s",
  type = "ease-in-out",
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const border = ({
  width = "0.15rem",
  type = "solid",
  color = "white",
}) => {
  return `border:${width} ${type} ${color}`
}

export const letterSpacing = ({ spacing = "0.1rem" }) => {
  return `letter-spacing:${spacing}`
}
