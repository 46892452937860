import styled from "styled-components"
import React from "react"
import img from "../images/bcg/homeBcg.jpeg"

// function based component
// pass down props to this component ({img and children})
function HomeHeader({ children, ...props }) {
  return <IndexHeader {...props}>{children}</IndexHeader>
}
function PageHeader({ children, ...props }) {
  return <IndexHeader {...props}>{children}</IndexHeader>
}
function AboutHeader({ children, ...props }) {
  return <BlendHeader {...props}>{children}</BlendHeader>
}
function CateringHeader({ children, ...props }) {
  return <OpaqueHeader {...props}>{children}</OpaqueHeader>
}

const IndexHeader = styled.section`
  --shape: 120% 60%;
  --from: oklch(0% 0 0 / 0.15);
  --to: oklch(0% 0 0 / 1);
  --img-url: url(${props => props.smImg || props.lgImg || props.img});

  min-height: 100vh;
  position: relative;

  background-image: var(--img-url),
    radial-gradient(var(--shape), var(--from), var(--to));
  background-blend-mode: darken;

  background-repeat: no-repeat;
  background-position: center;
  background-attachment: local;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 992px) {
    --shape: 140% 67%;
    --img-url: url(${props => props.lgImg || props.smImg || props.img});
  }
`

const BlendHeader = styled(IndexHeader)`
  background-image: url(${props => props.img}),
    radial-gradient(200% 50%, oklch(0% 0 0 / 0.6), oklch(0% 0 0 / 0.9));
`

const TanHeader = styled(IndexHeader)`
  background-color: #947e61;

  h1 {
    padding: 3rem 0 3rem 0;
  }
`
const OpaqueHeader = styled(IndexHeader)`
  background-image: url(${props => props.img}),
    linear-gradient(180deg, #929ba2, #ddbf84);
  background-blend-mode: darken;

  h1 {
    padding: 3rem 0 3rem 0;
  }
`

// fallback default images
HomeHeader.defaultProps = {
  img: img,
}
PageHeader.defaultProps = {
  img: img,
}

export { HomeHeader, PageHeader, AboutHeader, TanHeader, CateringHeader }
