// class based component rcc
import React, { Component } from "react"
import NavbarHeader from "./NavbarHeader"
import NavbarLinks from "./NavbarLinks"
import NavbarIcons from "./NavbarIcons"
import { colors } from "../../../utils/styles"
import styled from "styled-components"
import { Link } from "gatsby"
import navConfig from "./config"

export default class Navbar extends Component {
  state = {
    // only one property navbarOpen
    navbarOpen: false,
  }

  // this method controls the state of the navbar
  handleNavbar = () => {
    this.setState(() => {
      return { navbarOpen: !this.state.navbarOpen }
    })
  }

  // we want to pass the handleNavbar method down to header
  // and navbarOpen to links
  render() {
    return (
      <Header>
        <TopBar>
          <Link to="/menu/">View Menu</Link>
          <span>|</span>
          <a href="tel:8287745969">(828) 774-5969</a>
        </TopBar>
        <NavWrapper navbarOpen={this.state.navbarOpen} config={navConfig}>
          <NavbarHeader
            handleNavbar={this.handleNavbar}
            navbarOpen={this.state.navbarOpen}
          />
          <NavbarLinks navbarOpen={this.state.navbarOpen} />
          <NavbarIcons />
        </NavWrapper>
      </Header>
    )
  }
}

const Header = styled.header`
  height: fit-content;
  position: absolute;
  inset: 0;
  isolation: isolate;
  z-index: 10;
`

const TopBar = styled.div`
  background: ${colors.secondaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0.75rem 0;
  font-weight: 600;
  text-transform: uppercase;

  a {
    color: ${colors.mainBlack};
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
`

const NavWrapper = styled.nav`
  --bg-height: 100dvh;
  --px: 1rem;
  background-color: transparent;
  margin: auto;
  padding: 1rem var(--px);
  position: ${({ navbarOpen }) => (navbarOpen ? `fixed` : `relative`)};
  width: 100%;
  // transition: background 200ms, backdrop-filter 200ms 100ms;

  &::before {
    background: ${({ navbarOpen }) =>
      navbarOpen ? colors.primaryColor : `inherit`};
    // backdrop-filter: grayscale(0.5) contrast(5) blur(5px);
    content: "";
    height: var(--bg-height);
    inset: 0;
    position: absolute;
    scale: ${({ navbarOpen }) => (navbarOpen ? `1` : 0)};
    transition: background 200ms;
    width: 100dvw;
    z-index: -1;
  }

  @media (min-width: 576px) {
    --px: 2rem;
  }

  @media (min-width: ${({ config }) => config.breakpoint}) {
    --bg-height: 0;
    display: grid;
    grid-template: 1fr / auto 1fr auto;
    max-width: 1200px;
    align-items: center;
    place-content: space-between;
  }
`
